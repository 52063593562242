@import '../../../styles/common/common.scss';

.container {
    margin-top: var(--component-unhook-height);
    margin-bottom: var(--component-unhook-height);

    @include bp(lg) {
        margin-top: var(--component-unhook-height);
        margin-bottom: var(--component-unhook-height);
    }

    .content {
        justify-content: center;
        gap: 1rem;
    }

    .title {
        margin-top: rem(35px);

        @include bp(md) {
            margin-top: 0;
        }
    }

    .link {
        display: flex;
        align-items: center;
        margin-bottom: rem(32px);
        text-underline-offset: rem(12px);
        text-decoration-thickness: 1.5px;

        &:last-child {
            margin-bottom: 0;
        }

        @include hover-focus {
            text-underline-offset: rem(12px);
            text-decoration-thickness: rem(2px);
            font-weight: 800 !important;
        }
    }

    .button {
        box-shadow: 0 0 rem(15px) rgba(0, 0, 0, 0.3);
        text-decoration: none;
        margin-bottom: rem(32px);
        max-width: rem(300px);
        align-self: center;

        @include bp(md) {
            align-self: unset;
        }

        &:link,
        &:visited,
        &:focus,
        &:hover,
        &:active {
            text-decoration: none !important;
        }

        @include hover-focus {
            box-shadow: 0 0 rem(15px) rgba(0, 0, 0, 0.5);
            filter: brightness(1.2);
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .picture_right {
        order: -1;

        @include bp(md) {
            order: 0;
        }
    }
}

.is_dark {
    color: var(--utmb-color-white);
}
